@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;600;700;800&display=swap');

* {
  -webkit-appearance: none;
}

::-moz-selection {
  color: #fff;
  background: #5a2c80;
}

::selection {
  color: #fff;
  background: #5a2c80;
}

.input-ai::placeholder {
  color: #7c7c7c;
}

.card-bg {
  background: rgb(138 138 138 / 3%);
}

.bg-pattern {
  @apply bg-[url(https://cdn.dribbble.com/users/1770290/screenshots/6164788/bg_76.gif)] bg-cover;
}

textarea::placeholder, input::placeholder  {
  color: #7c7c7c;
  opacity: 1;
}

.btn-makit::placeholder  {
  opacity: 0.3;
}

.bg-blur {
  @apply border border-makit backdrop-filter backdrop-blur-3xl;
}

.bg-nav {
  @apply bg-blur bg-[#101014]/[0.5];
}

.btn-makit {
  @apply border text-xl text-center border-makit lg:px-20 py-4 rounded-2xl text-[#949494] bg-[#141414] hover:outline-none focus:outline-none backdrop-blur-3xl bg-opacity-20;
}

.btn-eye {
  @apply border lg:absolute text-xl text-center border-makit p-4 lg:ml-2 rounded-2xl text-[#949494] hover:outline-none focus:outline-none;
}

.btn-hover-effect {
  @apply hover:backdrop-blur-2xl focus:backdrop-blur-2xl hover:bg-opacity-30 focus:bg-opacity-30 ease-linear;
}

.btn-submit {
  @apply border text-center border-makit px-28 py-4 rounded-2xl text-[#949494] bg-[#494949] hover:outline-none focus:outline-none backdrop-blur-3xl bg-opacity-20 btn-hover-effect ;
}

.procedural-type {
  @apply border border-makit text-center py-4 px-20 rounded-xl text-[#fff] bg-transparent hover:outline-none focus:outline-none;
}

.bg-grad {
  background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.85));
}

.text-syne {
  font-family: 'Syne', sans-serif;
}

.text-white-mid {
  color: rgb(255 255 255 / 50%);
}

.border-makit {
  border: 1px solid hsla(0,0%,100%,.05);
}

.border-d-makit {
  border: 1px dashed hsla(0,0%,100%,.05);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: #fefefe!important;
  color-scheme: dark;
}

.status {
  animation: breath 2s infinite;
  @apply p-2 border border-green-500/[0.2] rounded-full;
}

@keyframes breath {
  0% {
    @apply text-green-700;
  }
  50% {
    @apply text-green-500;
  }
  100% {
    @apply text-green-700;
  }
}